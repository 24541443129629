import axios from "axios";
import { Product, PurchaseProtectRequest } from "../models";

export const getInventoryForLocation = async (locationUUID: string) => {
  const { data } = await axios.get<Product[]>(
    `${
      process.env.REACT_APP_API_URL
    }/lost-found/api/v1/locations/${locationUUID}/inventory`);
  
  return data;
};

export const postPurchaseProtectRequest = async (body: PurchaseProtectRequest) => {
  const { status } = await axios.post(
    `${process.env.REACT_APP_API_URL}/lost-found/api/v1/users/users/public`, body);

  return status;
}
