import { Col, Divider, Image, Layout, Row, Grid } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import './App.less';
import PosForm from './components/form/PosForm';
import logo from './assets/found-logo.png'
import placeholderLogo from './assets/found-logo.webp'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  const placeholder = <Image src={placeholderLogo}></Image>;
  const sideCol = <Col md={7} span={1}></Col>;
  const screens = Grid.useBreakpoint();

  return (
    <Router>
      <Layout className='App'>
        <Row justify='center'>
          {sideCol}
          <Col>
            <Image
              preview={false}
              placeholder={placeholder}
              width={'16vw'}
              style={{ minWidth: '180px' }}
              src={logo}
              alt={'The "Found" logo'}
            />
          </Col>
          {sideCol}
        </Row>
        <Divider />
        <Row justify='center'>
          {sideCol}
          <Col md={10} span={22}>
            <Title
              level={screens.sm ? 2 : 4}
            >Let's protect your shiny new thing!</Title>
          </Col>
          {sideCol}
        </Row>
        <Row>
          {sideCol}
          <Col md={10} span={22}>
            <Switch>
              <Route path='/:posBusinessPartnerUUID' component={PosForm} />
            </Switch>
          </Col>
          {sideCol}
        </Row>
      </Layout>
    </Router>
  );
}

export default App;
