import { AutoComplete, Button, Checkbox, Divider, Form, Input, Modal, Image } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInventoryForLocation, postPurchaseProtectRequest } from '../../effects';
import { Product, PurchaseProtectFormValues } from '../../models';
import './PosForm.less'
import sirFoundalotImage from '../../assets/sir-foundalot.png';

const initialValues: PurchaseProtectFormValues = {
  acceptMarketing: false,
  brand: '',
  category: '',
  email: '',
  model: '',
  name: ''
};

const distinctOptions = (
  products: Product[],
  key: keyof Product,
  filter?: (product: Product) => boolean) => Array
    .from(new Set(products
      .filter(filter || (_ => true))
      .map(p => p[key])))
    .map(value => ({ value }));

const PosForm: React.FC = () => {
  const { posBusinessPartnerUUID } = useParams<{ posBusinessPartnerUUID: string }>();

  const onFinish = (values: typeof initialValues) => {
    const [firstName, ...lastNames] = values.name.split(' ');
    const lastName = lastNames?.join(' ') || '';

    postPurchaseProtectRequest({
      acceptMarketing: values.acceptMarketing,
      itemBrand: values.brand,
      email: values.email,
      firstName,
      lastName,
      itemCategory: values.category,
      itemModel: values.model,
      origin: 'POS',
      posBusinessPartnerUUID
    }).then(() => Modal
      .success({
        style: {justifyContent: 'center', justifyItems: "center"},
        icon: (<Image style={{marginRight: '16px'}} width={200} src={sirFoundalotImage} />),
        content: 'Thank you! Please check your emails for details of how to access your Found account.',
        onOk: () => form.resetFields()
      }))
      .catch(() => Modal
        .error({
          centered: true,
          content: 'Sorry, something went wrong during sign up. We\'ll try our best to fix it.',
          onOk: () => form.resetFields()
        }));
  };
  const [form] = Form.useForm<typeof initialValues>();
  const [products, setProducts] = useState<Product[]>([]);
  const [suggestedBrands, setSuggestedBrands] = useState<{ value: string }[]>([]);
  const [suggestedModels, setSuggestedModels] = useState<{ value: string }[]>([]);

  useEffect(() => {
    const initProducts = async () => {
      setProducts(await getInventoryForLocation(posBusinessPartnerUUID));
    }

    initProducts();
  }, [posBusinessPartnerUUID]);

  const filterOption: AutoCompleteProps['filterOption'] = (inputValue, option) => {
    const value = option?.value.toUpperCase();
    return value.includes(inputValue.toUpperCase());
  }

  return (
    <Form
      form={form}
      name='pos'
      initialValues={initialValues}
      onFinish={onFinish}
      layout='vertical'
    >
      <Text>Tell us about you</Text>
      <Form.Item
        label={'What\'s your name?'}
        name='name'
        rules={[{
          type: 'string',
          required: true,
          message: 'Please tell us your name'
        }]}
      >
        <Input autoComplete='off' placeholder={'Sir Foundalot'} />
      </Form.Item>

      <Form.Item
        label={'What\'s your email address?'}
        name='email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please supply a valid email address'
        }]}
      >
        <Input autoComplete='off' placeholder={'foundalot@found.cloud'} />
      </Form.Item>

      <Divider />

      <Text>Tell us about your new purchase</Text>
      <Form.Item
        label={'What is the category of item?'}
        name='category'
        rules={[{
          transform: (val: string | undefined) => val?.toUpperCase()
        },
        {
          required: true,
          validator: (_, value) => {
            const categories = distinctOptions(products, 'category').map(v => v.value);
            if (categories.includes(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Please choose a valid category');
          }
        }]}
      >
        <AutoComplete
          placeholder={'Category'}
          options={distinctOptions(products, 'category')}
          onChange={category => setSuggestedBrands(distinctOptions(
            products,
            'brand',
            p => p.category === category
          ))}
          filterOption={filterOption}
        >
          <Input.Search />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        label={'Select the brand'}
        name='brand'
        rules={[]}
        dependencies={['category']}
      >
        <AutoComplete
          placeholder={'Brand'}
          options={suggestedBrands}
          disabled={false}
          onChange={brand => setSuggestedModels(distinctOptions(
            products,
            'model',
            p => p.brand === brand
          ))}
          filterOption={filterOption}
        >
          <Input.Search />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        label={'Select the model'}
        name='model'
        rules={[]}
        dependencies={['brand']}
      >
        <AutoComplete
          placeholder={'Model'}
          options={suggestedModels}
          disabled={false}
          filterOption={filterOption}
        >
          <Input.Search />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        name='acceptMarketing'
        valuePropName='checked'
      >
        <Checkbox><Text>Would you like to occasionally receive news and offers from us?</Text></Checkbox>
      </Form.Item>

      <Form.Item
        name='termsAndConditions'
        valuePropName='checked'
        rules={[{
          required: true,
          transform: value => (value || undefined),
          type: 'boolean',
          message: 'Please accept the terms and conditions'
        }]}
      >
        <Checkbox><Text>
          Do you accept the <a
            href='https://www.found.cloud/legal'
            rel="noopener noreferrer"
            target="_blank">terms and conditons</a>?
        </Text></Checkbox>
      </Form.Item>

      <Form.Item>
        <Button size={'large'} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PosForm;
